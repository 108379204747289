export default [
  {
    index: 0,
    key: "bioma",
    labelKey: "biome",
    color: "#222222",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:biome"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IGAC, adaptado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2008' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/colombia/collection_1/download/layers/bioma-pais.zip'
  },
  {
    index: 1,
    key: "pais",
    labelKey: "country",
    color: "#fdbf6f",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:country"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IGAC, DANE, adaptado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2021' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/colombia/collection_1/download/layers/nivel-politico-1.zip'
  },
  {
    index: 2,
    key: "departamento",
    labelKey: "department",
    color: "#eb2f06",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:department"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IGAC, DANE, adaptado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2021' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/colombia/collection_1/download/layers/nivel-politico-2.zip'
  },
  {
    index: 3,
    key: "municipio",
    labelKey: "municipality",
    color: "#947438",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:municipality"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IGAC, DANE, adaptado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2021' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/colombia/collection_1/download/layers/nivel-politico-3.zip'
  },
  {
    index: 4,
    key: "cuenca_hidrográfica",
    labelKey: "watershed",
    color: "#2980B9",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:watershed"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IDEAM, adpatado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/colombia/collection_1/download/layers/unidad-hidrografica.zip'
  },
  {
    index: 5,
    key: "regiones_biogeográficas",
    labelKey: "biogeographic_regions",
    color: "#55f8f8",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:biogeographic_regions"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Unidades Biogeográficas de Colombia, adaptado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2003' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/colombia/collection_1/download/layers/regiones-biogeograficas.zip'
  },
  {
    index: 6,
    key: "resguardos_indígenas",
    labelKey: "indigenous_territories",
    color: "#fc795b",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:indigenous_territories"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ANT, adaptado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/colombia/collection_1/download/layers/resguardo-indigena.zip'
  },
  {
    index: 7,
    key: "area_nacional_natural_protegida",
    labelKey: "national_natural_protected_area",
    color: "#6a3d9a",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:national_natural_protected_area"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'RUNAP, adaptado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/colombia/collection_1/download/layers/area-natural-protegida-nacional.zip'
  },
  {
    index: 8,
    key: "area_departamental_natural_protegida",
    labelKey: "departmental_natural_protected_area",
    color: "#730e21",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:departmental_natural_protected_area"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'RUNAP, adaptado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/colombia/collection_1/download/layers/area-natural-protegida-departamental.zip'
  },
  {
    index: 9,
    key: "consejos_comunitarios",
    labelKey: "community_councils",
    color: "#7f6b83",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:community_councils"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ANT' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/colombia/collection_1/download/layers/consejos-comunitarios.zip'
  },
  {
    index: 10,
    key: "sitio_ramsar",
    labelKey: "ramsar_site",
    color: "#DFFF00",
    props: {
      url: 'https://shared-geoserver.mapbiomas.org/geoserver/ows',
      transparent: true,
      format: 'image/png',
      layers: "mapbiomas-colombia:ramsar_site"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Ministerio de ambiente y Desarollo Sostenible,  adpatado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2020' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/colombia/collection_1/download/layers/ramsar.zip'
  },
  {
    index: 11,
    key: "reservas_forestales_por_la_ley_2",
    labelKey: "forest_reserves_by_Law_2",
    color: "#27AE60",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:forest_reserves_by_Law_2"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Ministerio de ambiente y Desarollo Sostenible,  adpatado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2020' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Av_layers_biosphere_reserve&outputFormat=SHAPE-ZIP'
  },
  {
    index: 12,
    key: "biomas_nacionales",
    labelKey: "national_biomes",
    color: "#CCCCFF",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:national_biomes"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IDEAM, adaptado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2017' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Av_layers_biosphere_reserve&outputFormat=SHAPE-ZIP'
  },
  {
    index: 13,
    key: "limite_car",
    labelKey: "boundary_car",
    color: "#FF00FF",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:boundary_car"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Ministerio de ambiente y Desarollo Sostenible,  adpatado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2019' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Av_layers_biosphere_reserve&outputFormat=SHAPE-ZIP'
  },
  {
    index: 14,
    key: "reservas_de_la_biosfera",
    labelKey: "biosphere_reserves",
    color: "#008080",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-colombia:biosphere_reserves"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Ministerio de ambiente y Desarollo Sostenible,  adpatado por FGA' },
      { labelKey: 'year', label: 'Ano', value: '2020' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Av_layers_biosphere_reserve&outputFormat=SHAPE-ZIP'
  }
];
