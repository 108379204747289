export default {
  'default': {
    'territorial': [
      'country',
      'biome',
      'department',
      'municipality',
      'watershed',
      'biogeographic_regions',
    ],
    'land': [
      'indigenous_territories',
      'national_natural_protected_area',
      'departmental_natural_protected_area',
      'community_councils',
      'ramsar_site',
      'forest_reserves_by_Law',
      'national_biomes',
    ]
  },
  'simplified': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'special_territories',
      'biosphere_reserves',
      'geoparks',
    ]
  },
  'infrastructure': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
    ]
  },
  'fire': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
      'special_territories',
      'biosphere_reserve',
      'geoparks',
    ],
    'land': [
      'settlement',
      'quilombo',
      'indigenous_land_new',
      'federal_conservation_units_integral_protection',
      'federal_conservation_units_sustainable_use',
      'state_conservation_units_integral_protection',
      'state_conservation_units_sustainable_use',
    ]
  },
  'soil': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
    ]
  },
  'sentinel': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
      'special_territories',
    ],
    'land': [
      'settlement',
      'quilombo',
      'indigenous_land_new',
      'federal_conservation_units_integral_protection',
      'federal_conservation_units_sustainable_use',
      'state_conservation_units_integral_protection',
      'state_conservation_units_sustainable_use',
    ]
  }
};
